import { useCharacterSearch } from "hooks/asocijator/character/useCharacterSearch";
import styled from "styled-components";
import RelateCharShow from "./RelateCharShow";
import { useEffect, useState } from "react";
import {
  charPosDimOpsInitial,
  posOpacityFromCharPosDimOp,
  relateCharKeyGet,
} from "./logic/RelateCharactersShow.logic";
import { CharPosDimOpacity } from "./RelateCharactersShow.type";
import { relCharPosOpsCalc } from "./logic/RelateCharactersShowCalc.logic";
import useAnswerPlayChar from "common/answer/provider/AnswerPlayChar";

export type RelateCharactersShowProps = {
  relCharAnswerShow: boolean;
};

export type LetterPosition = {
  top: number;
  left: number;
};

const RelateCharactersShow = ({
  relCharAnswerShow,
}: RelateCharactersShowProps) => {
  const { playCharId } = useAnswerPlayChar();

  const characterListResult = useCharacterSearch({
    skip: 0,
    limit: 0,
    searchType: "CHARACTER_RELATE",
    characterId: playCharId,
  });
  const [relCharPosDimOps, setRelCharPosDimOps] = useState<CharPosDimOpacity[]>(
    []
  );

  useEffect(() => {
    if (characterListResult) {
      const relateCharPosDimOps = charPosDimOpsInitial(
        characterListResult.items
      );
      setRelCharPosDimOps(relateCharPosDimOps);
    }
  }, [characterListResult]);

  useEffect(() => {
    if (relCharPosDimOps.length > 0) {
      relCharPosOpsCalc(relCharPosDimOps).then((relateCharPosDimOps) => {
        setRelCharPosDimOps(relateCharPosDimOps);
      });
    }
  }, [relCharPosDimOps.length]);

  return relCharPosDimOps.length > 0 ? (
    <Div>
      {relCharPosDimOps.map(
        (charPosDimOpacity: CharPosDimOpacity, ind: number) => (
          <RelateCharShow
            key={relateCharKeyGet(ind)}
            character={charPosDimOpacity.character}
            ind={ind}
            relateCharPosOpacity={posOpacityFromCharPosDimOp(charPosDimOpacity)}
            relCharAnswerShow={relCharAnswerShow}
          />
        )
      )}
    </Div>
  ) : null;
};

const Div = styled.div`
  position: absolute;
  font-size: 2rem;
  margin-top: 0rem;
  margin-left: 0rem;
`;

export default RelateCharactersShow;
