import { CharPosDimOpacity } from "../RelateCharactersShow.type";
import PosDim from "model/PosDim";
import {
  PosDimResult,
  WindowDimResult,
  elIdPosDimGet,
  windowDimGet,
} from "common/html/element_pos_dim";
import Point from "model/Point";
import {
  playCharPosDimGet,
  playCharRelCharsCheck,
  relateCharKeyGet,
} from "./RelateCharactersShow.logic";
import { randInRangeGet } from "common/rand_common";

export async function relCharPosOpsCalc(
  charPosDimOps: CharPosDimOpacity[]
): Promise<CharPosDimOpacity[]> {
  let result: CharPosDimOpacity[] = [...charPosDimOps];
  const relCharsCount: number = charPosDimOps.length;
  const charElsCh = await playCharRelCharsCheck(relCharsCount);
  if (charElsCh) {
    result = relCharPosOpsDimensionsCalc(result);
    result = relCharPositionsCalc(result);
  }

  return result;
}

function relCharPositionsCalc(
  charPosDimOps: CharPosDimOpacity[]
): CharPosDimOpacity[] {
  const result = [...charPosDimOps];

  const windowDim = windowDimGet();
  const playCharPosDim = playCharPosDimGet();

  if (playCharPosDim !== null) {
    for (let i = 0; i < charPosDimOps.length; i++) {
      const chPosDimOp: CharPosDimOpacity = relCharPositionIndCalc(
        charPosDimOps,
        i,
        windowDim,
        playCharPosDim
      );
      result[i] = chPosDimOp;
    }
  }

  return result;
}

function relCharPositionIndCalc(
  charPosDimOps: CharPosDimOpacity[],
  ind: number,
  windowDim: WindowDimResult,
  playCharPosDim: PosDim
): CharPosDimOpacity {
  const result = { ...charPosDimOps[ind] };
  const posDim: PosDim = result.posDim;

  const maxTryCount: number = 100;
  const margin: number = 10;

  const minLeft: number = margin;
  const minTop: number = margin;
  const maxLeft: number = windowDim.width - posDim.width - margin;
  const maxTop: number = windowDim.height - posDim.height - margin;

  let posCh: boolean = false;

  let candidatePosDim: PosDim = new PosDim();

  for (let i = 1; i <= maxTryCount; i++) {
    candidatePosDim = candidatePosDimGet(
      posDim,
      minLeft,
      maxLeft,
      minTop,
      maxTop
    );
    const playCharIntersectNearCh: boolean = candidatePosDim.intersectNearCheck(
      playCharPosDim,
      margin
    );
    if (!playCharIntersectNearCh) {
      posCh = true;
      const posRelCh = relCharIntersectNearCheck(
        candidatePosDim,
        charPosDimOps,
        margin
      );
      if (!posRelCh) {
        posCh = false;
      }
      if (posCh) {
        break;
      }
    }
  }

  if (posCh) {
    result.posDim.start = candidatePosDim.start;
    result.opacity = 1;
  }

  return result;
}

function candidatePosDimGet(
  posDim: PosDim,
  minLeft: number,
  maxLeft: number,
  minTop: number,
  maxTop: number
): PosDim {
  let candidatePosDim: PosDim = new PosDim();
  const left = randInRangeGet(minLeft, maxLeft);
  const top = randInRangeGet(minTop, maxTop);
  const start: Point = new Point(left, top);
  candidatePosDim = new PosDim(start, posDim.width, posDim.height);

  return candidatePosDim;
}

function relCharIntersectNearCheck(
  posDim: PosDim,
  charPosDimOps: CharPosDimOpacity[],
  margin: number
): boolean {
  let result: boolean = true;
  for (let j = 0; j < charPosDimOps.length; j++) {
    const chPosDimOp: CharPosDimOpacity = charPosDimOps[j];
    if (chPosDimOp.opacity === 1) {
      const relCharIntersectNearCh: boolean = posDim.intersectNearCheck(
        chPosDimOp.posDim,
        margin
      );
      if (relCharIntersectNearCh) {
        result = false;
        break;
      }
    }
  }

  return result;
}

function relCharPosOpsDimensionsCalc(
  charPosDimOps: CharPosDimOpacity[]
): CharPosDimOpacity[] {
  const result = charPosDimOps.map((item: CharPosDimOpacity, ind: number) =>
    relCharPosOpDimCalc(item, ind)
  );

  return result;
}

function relCharPosOpDimCalc(
  charPosDimOp: CharPosDimOpacity,
  ind: number
): CharPosDimOpacity {
  const res: CharPosDimOpacity = { ...charPosDimOp };
  const elId: string = relateCharKeyGet(ind);
  const posDimResult: PosDimResult | null = elIdPosDimGet(elId);
  if (posDimResult !== null) {
    const start: Point = new Point(posDimResult.left, posDimResult.top);
    const posDim: PosDim = new PosDim(
      start,
      posDimResult.width,
      posDimResult.height
    );
    res.posDim = posDim;
  }
  return res;
}
