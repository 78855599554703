import Point from "model/Point";

export default class PosDim {
  start = new Point();
  width = 0;
  height = 0;

  constructor(
    start: Point = new Point(),
    width: number = 0,
    height: number = 0
  ) {
    this.start = start;
    this.width = width;
    this.height = height;
  }

  posDimFromNums(
    left: number = 0,
    top: number = 0,
    width: number = 0,
    height: number = 0
  ): PosDim {
    const start: Point = new Point(left, top);
    const posDim: PosDim = new PosDim(start, width, height);

    return posDim;
  }

  containsPoint(P: Point): boolean {
    let ch: boolean = false;
    const left: number = this.start.left;
    const top: number = this.start.top;

    if (
      P.left >= left &&
      P.top >= top &&
      P.left <= left + this.width &&
      P.top <= top + this.height
    ) {
      ch = true;
    }

    return ch;
  }

  aroundPosDimGet(d: number): PosDim {
    const left: number = this.start.left;
    const top: number = this.start.top;

    const posDimAround: PosDim = this.posDimFromNums(
      left - d,
      top - d,
      this.width + 2 * d,
      this.height + 2 * d
    );

    return posDimAround;
  }

  containsNearPoint(P: Point, d: number): boolean {
    const posDimAround: PosDim = this.aroundPosDimGet(d);

    return posDimAround.containsPoint(P);
  }

  intersectCheck(posDim: PosDim): boolean {
    const left: number = this.start.left;
    const top: number = this.start.top;
    const S2: Point = new Point(left + this.width, top + this.height);
    const ch: boolean =
      this.containsPoint(posDim.start) ||
      posDim.containsPoint(this.start) ||
      posDim.containsPoint(S2);

    return ch;
  }

  intersectNearCheck(posDim: PosDim, d: number): boolean {
    const posDimAround: PosDim = this.aroundPosDimGet(d);

    return posDimAround.intersectCheck(posDim);
  }
}
