import styled from "styled-components";

const DivPosOpacity = styled.div<{
  top?: number;
  left?: number;
  opacity?: number;
  display?: string;
  flexDirection?: string;
  alignItems?: string;
}>`
  position: absolute;
  width: fit-content;
  margin-top: ${(props) => props.top ?? 0}px;
  margin-left: ${(props) => props.left ?? 0}px;
  opacity: ${(props) => props.opacity ?? 1};
  display: ${(props) => props.display ?? ""};
  flex-direction: ${(props) => props.flexDirection ?? ""};
  align-items: ${(props) => props.alignItems ?? ""};
`;

export default DivPosOpacity;
