import { intervalCheck } from "common/common";
import { PLAY_CHAR_ID } from "../../PlayCharacter/PlayCharacter.const";
import { CharacterResult } from "common/character/dto/CharacterResult";
import { CharPosDimOpacity } from "../RelateCharactersShow.type";
import PosDim from "model/PosDim";
import { elIdsCheck } from "common/html/element_common";
import { PosDimResult, elIdPosDimGet } from "common/html/element_pos_dim";
import Point from "model/Point";
import { PosOpacity } from "../../TopicAnswerPlay.type";

export function relateCharKeyGet(ind: number): string {
  let result: string = `relate_char_${ind}`;

  return result;
}

export async function playCharPosCheck(): Promise<boolean> {
  const result: boolean = await intervalCheck(playCharOpacityCheck, 3 * 1000);

  return result;
}

export async function relCharElsCheck(relCharsCount: number): Promise<boolean> {
  const ids: string[] = relCharIdsGet(relCharsCount);
  const result: boolean = await elIdsCheck(ids, 3 * 1000);

  return result;
}

function playCharOpacityCheck(): boolean {
  let res: boolean = false;
  const el = document.getElementById(PLAY_CHAR_ID);
  if (el !== null) {
    const elStyle = getComputedStyle(el);
    const opacity = elStyle.opacity;

    if (parseFloat(opacity) > 0) {
      res = true;
    }
  }

  return res;
}

export function charPosDimOpsInitial(
  characters: CharacterResult[]
): CharPosDimOpacity[] {
  const result: CharPosDimOpacity[] = characters.map(
    (character: CharacterResult) => charPosDimOpInit(character)
  );

  return result;
}

export function charPosDimOpInit(
  character: CharacterResult
): CharPosDimOpacity {
  return {
    character,
    posDim: new PosDim(),
    opacity: 0,
  };
}

export function posOpacityFromCharPosDimOp(
  charPosDimOp: CharPosDimOpacity
): PosOpacity {
  return {
    start: charPosDimOp.posDim.start,
    opacity: charPosDimOp.opacity,
  };
}

export function relCharIdsGet(relCharsCount: number): string[] {
  const relChIds: string[] = [];
  for (let i = 0; i < relCharsCount; i++) {
    const id: string = relateCharKeyGet(i);
    relChIds.push(id);
  }

  return relChIds;
}

export async function playCharRelCharsCheck(
  relCharsCount: number
): Promise<boolean> {
  let result: boolean = false;
  const playCharCh: boolean = await playCharPosCheck();

  if (playCharCh) {
    const relCharsCh: boolean = await relCharElsCheck(relCharsCount);
    if (relCharsCh) {
      result = true;
    }
  }

  return result;
}

export function playCharPosDimGet(): PosDim {
  const posDimRes: PosDimResult | null = elIdPosDimGet(PLAY_CHAR_ID);

  let result: PosDim = new PosDim();

  if (posDimRes !== null) {
    const playCharStart: Point = new Point(posDimRes.left, posDimRes.top);
    result = new PosDim(playCharStart, posDimRes.width, posDimRes.height);
  }

  return result;
}
