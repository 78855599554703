import { CharacterResult } from "common/character/dto/CharacterResult";
import { relateCharKeyGet } from "../logic/RelateCharactersShow.logic";
import DivPosOpacity from "components/common/position/DivPosOpacity";
import { PosOpacity } from "../../TopicAnswerPlay.type";

export type RelateCharShowProps = {
  character: CharacterResult;
  ind: number;
  relateCharPosOpacity: PosOpacity;
  relCharAnswerShow: boolean;
};

const RelateCharShow = ({
  character,
  ind,
  relateCharPosOpacity,
  relCharAnswerShow,
}: RelateCharShowProps) => {
  return (
    <DivPosOpacity
      id={relateCharKeyGet(ind)}
      top={relateCharPosOpacity.start.top}
      left={relateCharPosOpacity.start.left}
      opacity={relateCharPosOpacity.opacity}
    >
      <div>{character.letter}</div>
      {relCharAnswerShow && (
        <>
          <div>{character.pronounce}</div>
          <div>{character.meaning ?? ""}</div>
        </>
      )}
    </DivPosOpacity>
  );
};

export default RelateCharShow;
